import groupAdmins from './groupAdmins.json';
import createSchema from '../schema/create/groupAdmins.json';
import postResponse from './responseExamples/200/post.json';
import getListResponse from './responseExamples/200/getList.json';

groupAdmins.operations[0].request.schema = createSchema;

groupAdmins.operations[0].responces[0].example = postResponse;
groupAdmins.operations[1].responces[0].example = getListResponse;
groupAdmins.operations[2].responces[0].example = postResponse;

export default groupAdmins;
