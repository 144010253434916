import _ from 'lodash';
import filterTypes from 'kaiten-lib/lib/shared/filter3Types';
import customPropertyTypes from 'kaiten-lib/lib/shared/customPropertyTypes';
import filterMap from './data/filterMap';

const getCustomPropertyDataGroupByComparison = (type) => _.reduce(
  _.keys(filterMap.custom_property[type]),
  (acc, comparison) => [...acc, { key: comparison, entries: [comparison, 'object', `Filter by operator ${comparison}`] }],
  [],
);

const getCustomPropertyDataGroupByType = () => _.reduce(
  customPropertyTypes,
  (acc, key) => [...acc, { key, entries: [key, 'object', `Filter by custom property of type "${key}"`] }],
  [],
);

const getPropertiesDataForKey = (key, object, customPropertyType) => _.reduce(
  _.keys(object),
  (acc, comparison) => {
    const value = object[comparison];

    const extraValueParams = value.type === 'object' ? {
      linkField: 'Type',
      objectProperties: {
        value: {
          schema: {
            columns: ['Field name', 'Type', 'Constraint', 'Required', 'Description'],
            data: _.map(_.entries(value.properties), ([property, propertyValue]) => ({
              key: property,
              entries: [property, propertyValue.type, '', propertyValue.required ?? 'yes', propertyValue.description],
            })),
          },
        },
      },
    } : {};

    const customPropertyExtraParams = key === filterTypes.custom_property
      ? [
        { key: 'id', entries: ['id', 'number', '', 'yes', 'Custom property id'] },
        { key: 'type', entries: ['type', 'enum', customPropertyType, 'yes', 'Custom property type'] },
      ] : [];

    return {
      ...acc,
      [comparison]: {
        schema: {
          columns: ['Field name', 'Type', 'Constraint', 'Required', 'Description'],
          data: [
            { key: 'key', entries: ['key', 'enum', key, 'yes', 'Predicate name'] },
            { key: 'comparison', entries: ['comparison', 'enum', comparison, 'yes', 'Comparative operator'] },
            value.type ? { key: 'value', entries: ['value', value.type, _.join(value.constraints, ', '), 'yes', 'Value to compare with'] } : {},
            ...customPropertyExtraParams,
          ],
          ...extraValueParams,
        },
      },
    };
  },
  {},
);

const getCustomPropertyAttributesGroupByComparison = () => _.reduce(
  customPropertyTypes,
  (acc, customPropertyType) => ({
    ...acc,
    [customPropertyType]: {
      schema: {
        columns: ['Comparison', 'Attributes', 'Description'],
        data: getCustomPropertyDataGroupByComparison(customPropertyType),
        linkField: 'Attributes',
        objectProperties: getPropertiesDataForKey(
          filterTypes.custom_property,
          filterMap.custom_property[customPropertyType],
          customPropertyType,
        ),
      },
    },
  }),
  {},
);

const getFilterDataGroupByComparison = (key) => _.reduce(
  _.keys(filterMap[key]),
  (acc, comparison) => [...acc, { key: comparison, entries: [comparison, 'object', `Filter by operator ${comparison}`] }],
  [],
);

const getFilterDataGroupByKey = () => _.reduce(
  _.keys(filterMap),
  (acc, key) => [...acc, { key, entries: [key, 'object', `Predicate to filter by ${key}`] }],
  [],
);

const getAttributes = () => _.reduce(
  _.keys(filterMap),
  (acc, key) => {
    if (key === filterTypes.custom_property) {
      return {
        ...acc,
        custom_property: {
          schema: {
            columns: ['Custom property type', 'Attributes', 'Description'],
            data: getCustomPropertyDataGroupByType(),
            linkField: 'Attributes',
            objectProperties: getCustomPropertyAttributesGroupByComparison(),
          },
        },
      };
    }

    return {
      ...acc,
      [key]: {
        schema: {
          columns: ['Comparison', 'Attributes', 'Description'],
          data: getFilterDataGroupByComparison(key),
          linkField: 'Attributes',
          objectProperties: getPropertiesDataForKey(key, filterMap[key]),
        },
      },
    };
  },
  {},
);

export default {
  columns: ['Field name', 'Type', 'Constraint', 'Required', 'Description'],
  data: [
    { key: 'key', entries: ['key', 'enum', 'and, or', 'yes', 'Top level operator'] },
    { key: 'value', entries: ['value', 'array of objects', '', 'yes', 'List of filter groups'] },
  ],
  linkField: 'Type',
  objectProperties: {
    value: {
      schema: {
        columns: ['Field name', 'Type', 'Constraint', 'Required', 'Description'],
        data: [
          { key: 'key', entries: ['key', 'enum', 'and, or', 'yes', 'Group operator'] },
          { key: 'value', entries: ['value', 'array of objects', '', 'yes', 'List of group filters'] },
        ],
        linkField: 'Type',
        objectProperties: {
          value: {
            schema: {
              columns: ['Key', 'Attributes', 'Description'],
              data: getFilterDataGroupByKey(),
              linkField: 'Attributes',
              objectProperties: getAttributes(),
            },
          },
        },
      },
    },
  },
};
