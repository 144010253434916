import React, {
  useState, useRef, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { serialize } from 'object-to-formdata';
import _ from 'lodash';
import {
  TextField, Box, Typography, Grid, Button,
} from '@mui/material';
import { If, Then, Else } from 'react-if';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import CodeExample from './CodeExample';
import makeRequest from '../utils/makeRequest';
import ResponseBox from './ResponseBox';
import validators from '../utils/validators';

const exampleTypes = {
  json: 'application/json',
  multipart: 'multipart/form-data',
};

const Wrapper = styled(Box)(
  ({ theme }) => ({
    marginBottom: theme.spacing(2),
  }),
);

const Text = styled(Typography)(
  ({ theme }) => ({
    marginBottom: theme.spacing(1),
  }),
);

const StyledTextField = styled(TextField)(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
  }),
);
const CodeExampleWrapper = styled('div')({
  paddingBottom: '5px',
});
const ButtonWrapper = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    padding: `12px ${theme.spacing(1)}  12px ${theme.spacing(1)}`,
  }),
);
const StyledButton = styled(Button)({
  marginLeft: 'auto',
});

const setDefaultState = (data = []) => data.reduce((acc, { reference }) => {
  acc[reference] = '';
  return acc;
}, {});
const generateString = (min = 1, max = 4) => {
  const string = 'testDataString';
  const baseLength = 4;
  if (baseLength > max) {
    return string.substr(0, max);
  }
  return string.substr(0, baseLength < min ? min : baseLength);
};
const checkType = (type, data = {}) => {
  if (type === 'string') return generateString(data.minLength, data.maxLength);
  if (type === 'number' || type === 'integer') return data.minimum || 1;
  if (type === 'boolean') return false;
  if (type === 'null') return null;
  if (type === 'array') return [];
  if (type === 'object') return {};
};

const parseBodySchema = (schema, examples = {}) => {
  const propsKeys = _.keys(schema.properties);
  const result = propsKeys.reduce((acc, propName) => {
    const propData = schema.properties[propName];
    if (examples[propName]) {
      acc[propName] = examples[propName];
      return acc;
    }
    if (propData.type) {
      acc[propName] = Array.isArray(propData.type)
        ? checkType(propData.type[0])
        : checkType(propData.type, propData);
    }
    if (propData.enum) {
      const [data] = propData.enum;
      acc[propName] = data;
    }
    if (propData.oneOf) {
      acc[propName] = Array.isArray(propData.oneOf[0].type)
        ? checkType(propData.oneOf[0].type[0])
        : checkType(propData.oneOf[0].type, propData.oneOf[0]);
    }
    return acc;
  }, {});
  return result;
};

const setDefautBody = (request, examples) => {
  if (!request) return;
  const { disableBodyEditor, schema, defaultData } = request;
  if (disableBodyEditor) return;
  if (defaultData) return JSON.stringify(defaultData, null, ' ');
  return schema
    ? JSON.stringify(parseBodySchema(schema, examples), null, ' ')
    : null;
};

const renderPathParams = (params = [], state = {}, onChange = _.noop) => {
  const { paramsValues, paramsErrors } = state;
  return params.map(({ name, reference }) => (
    <Grid item key={name} xs={12}>
      <TextField
        id={reference}
        label={name}
        value={paramsValues[reference]}
        error={paramsErrors[reference]}
        variant="outlined"
        size="small"
        fullWidth
        onChange={onChange}
      />
      <If condition={paramsErrors[reference]}>
        <Typography variant="caption" color="error">{paramsErrors[reference]}</Typography>
      </If>
    </Grid>
  ));
};

const renderQueryParams = (params = [], state = {}, onChange = _.noop) => {
  const { queryValues, queryErrors } = state;
  return params.map(({ name, type }) => (
    <Grid item key={name} xs={12}>
      <If condition={type === 'boolean'}>
        <Then>
          <FormControlLabel
            // сейчас показываеи только обязательные поля, поэтому чекбокс дисейблим
            control={<Checkbox defaultChecked required disabled />}
            label={name}
          />
        </Then>
        <Else>
          <TextField
            id={name}
            label={name}
            required
            value={queryValues[name]}
            error={queryErrors[name]}
            variant="outlined"
            size="small"
            fullWidth
            onChange={onChange}
          />
          <If condition={queryErrors[name]}>
            <Typography variant="caption" color="error">{queryErrors[name]}</Typography>
          </If>
        </Else>
      </If>
    </Grid>
  ));
};

const renderFiles = (state, onChange, files = [], allowMultipleFiles = false) => {
  const { filesErrors } = state;
  return files.map((name) => (
    <Grid item key={name} xs={12}>
      <TextField
        id={name}
        error={filesErrors[name]}
        type="file"
        variant="outlined"
        size="small"
        fullWidth
        onChange={onChange}
        inputProps={{ multiple: allowMultipleFiles }}
      />
    </Grid>
  ));
};

const formPathFromParams = (path, paramsValues, params) => path.replace(/(\{[^{]+\})/gm, (match) => {
  const name = match.slice(1, -1);
  const paramName = _.find(params, (param) => param.name === name).reference;
  return paramsValues[paramName] ? paramsValues[paramName].trim() : match;
});

const parseBody = (body) => new Promise((resolve) => {
  if (!body) resolve({});
  const bodyData = JSON.parse(body);
  resolve(bodyData);
}).catch(() => {
  const error = new Error();
  error.type = 'JsonError';
  throw error;
});

const additionValidate = (validateOption, value) => {
  switch (validateOption.validator) {
    case 'dateFormat': {
      const error = validators[validateOption.validator](value, validateOption.format);
      return error;
    }
    default: {
      throw new Error('unsupported validate format');
    }
  }
};

const validate = (params) => {
  const {
    domain,
    token,
    paramsValues = {},
    pathParams = [],
    queryValues = {},
    queryParams = [],
  } = params;
  const errors = {};
  if (!domain) {
    errors.domain = true;
  }
  if (!token) {
    errors.token = true;
  }
  pathParams.forEach(({ reference }) => {
    if (!paramsValues[reference]) {
      if (!errors.params) {
        errors.params = {};
      }
      errors.params[reference] = "Сan't be empty";
    }
  });
  _.forEach(queryParams, ({ name, type, validate: validateOptions }) => {
    if (type !== 'boolean' && _.isEmpty(queryValues[name])) {
      if (!errors.query) {
        errors.query = {};
      }
      errors.query[name] = "Сan't be empty";
      return;
    }
    if (validateOptions) {
      const error = additionValidate(validateOptions, queryValues[name]);
      if (error) {
        if (!errors.query) {
          errors.query = {};
        }
        errors.query[name] = error;
      }
    }
  });
  return errors;
};

const validateFiles = (values, files = []) => {
  const errors = {};
  files.forEach((name) => {
    if (!values[name]) {
      errors[name] = true;
    }
  });
  return errors;
};

const buildQuery = (query, queryValues) => {
  const params = new URLSearchParams();
  _.each(query, ({ name, type }) => {
    if (type === 'boolean') {
      params.append(name, true);
      return;
    }

    const value = queryValues[name];

    if (!value) {
      return;
    }
    params.append(name, _.trim(value));
  });
  return params;
};

function Interactive({
  operation,
  id,
  info,
  changeInfo,
  basePath,
}) {
  const {
    request, pathParams, attributesExample, type, path, query = [],
  } = operation;
  const {
    token, domain, body, bodyError,
  } = info;
  const [state, setState] = useState({
    res: null,
    paramsValues: {},
    paramsErrors: {},
    queryValues: {},
    queryErrors: {},
    filesValues: {},
    filesErrors: {},
    processing: false,
  });
  const previousRef = useRef();
  const previousValue = previousRef.current;
  const files = request && request.files;
  const requiredQueryParams = useMemo(() => _.filter(query, 'required'), [query]);

  if (id !== previousValue) {
    const paramsData = setDefaultState(pathParams);
    previousRef.current = id;
    setState((prevValue) => ({
      ...prevValue,
      paramsValues: { ...paramsData, ...prevValue.paramsValues },
      filesValues: _.zipObject(files),
      paramsErrors: {},
      res: null,
    }));
  }
  useEffect(() => {
    changeInfo((prevValue) => ({
      ...prevValue,
      tokenError: false,
      domainError: false,
      body: setDefautBody(request, attributesExample),
      bodyError: '',
    }));
  }, [id]);
  const formedPath = formPathFromParams(path, state.paramsValues, pathParams);

  const queryString = _.isEmpty(requiredQueryParams) ? '' : `?${buildQuery(requiredQueryParams, state.queryValues).toString()}`;

  const fullPath = `${basePath}/api/latest${formedPath}${queryString}`;
  const changePathParams = (event) => {
    setState((prevValue) => ({
      ...prevValue,
      paramsValues: { ...prevValue.paramsValues, [event.target.id]: event.target.value },
    }));
  };

  const changeQueryParams = (event) => {
    setState((prevValue) => ({
      ...prevValue,
      queryValues: { ...prevValue.queryValues, [event.target.id]: event.target.value },
    }));
  };

  const changeFilesValues = (event) => {
    setState((prevValue) => ({
      ...prevValue,
      filesValues: { ...prevValue.filesValues, [event.target.id]: event.target.files },
    }));
  };
  const changeBody = (event) => (
    changeInfo((prevValue) => ({ ...prevValue, body: event.target.value }))
  );

  const getTypeForExample = () => {
    if (!files) {
      return exampleTypes.json;
    }
    if (request?.fileisRequired) {
      return exampleTypes.multipart;
    }
    let isFilesChoosen = false;
    _.forEach(files, (fileFieldName) => {
      if (state.filesValues[fileFieldName]) {
        isFilesChoosen = true;
      }
    });
    return isFilesChoosen ? exampleTypes.multipart : exampleTypes.json;
  };

  const handleClick = () => {
    setState((prevValue) => ({
      ...prevValue,
      filesErrors: {},
      paramsErrors: {},
      queryErrors: {},
    }));
    changeInfo((prevValue) => ({
      ...prevValue,
      tokenError: false,
      domainError: false,
      bodyError: '',
    }));

    const errors = validate({
      domain,
      token,
      paramsValues: state.paramsValues,
      pathParams,
      queryValues: state.queryValues,
      queryParams: requiredQueryParams,
    });
    const filesValidationErrors = request?.fileisRequired
      ? validateFiles(state.filesValues, files)
      : [];
    if (_.keys(filesValidationErrors).length > 0) {
      errors.files = filesValidationErrors;
    }

    const errKeys = _.keys(errors);
    if (errKeys.length > 0) {
      errKeys.forEach((key) => {
        if (key === 'domain') changeInfo((prevValue) => ({ ...prevValue, domainError: true }));
        if (key === 'token') changeInfo((prevValue) => ({ ...prevValue, tokenError: true }));
        if (key === 'params') setState((prevValue) => ({ ...prevValue, paramsErrors: errors.params }));
        if (key === 'query') setState((prevValue) => ({ ...prevValue, queryErrors: errors.query }));
        if (key === 'files') setState((prevValue) => ({ ...prevValue, filesErrors: errors.files }));
      });
      return;
    }
    const headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'x-developers-proxy-domain': domain,
    };
    const requestPath = `${basePath}/api/latest${formedPath}`;
    const requestQuery = buildQuery(requiredQueryParams, state.queryValues);

    return parseBody(body)
      .then((parsedBody) => {
        let data = parsedBody;
        if (files) {
          data = serialize(
            parsedBody,
            { allowEmptyArrays: true }, // optional
          );
          files.forEach((file) => {
            _.forEach(state.filesValues[file], (uploadedFile) => {
              data.append(file, uploadedFile);
            });
          });
        }
        setState((prevValue) => ({
          ...prevValue,
          processing: true,
        }));
        return makeRequest(type, requestPath, headers, data, requestQuery);
      })
      .then((res) => {
        setState((prevValue) => ({
          ...prevValue,
          processing: false,
          res: {
            code: 200,
            type: 'success',
            data: res.data,
          },
        }));
      })
      .catch((error) => {
        if (error.type === 'JsonError') {
          changeInfo((prevValue) => ({ ...prevValue, bodyError: 'Incorrect Json' }));
          return;
        }
        if (!error.response) {
          setState((prevValue) => ({
            ...prevValue,
            processing: false,
            res: {
              type: 'error',
              data: 'Network Error',
            },
          }));
          return;
        }
        setState((prevValue) => ({
          ...prevValue,
          processing: false,
          res: {
            code: error.response.status,
            type: 'error',
            data: error.response.data,
          },
        }));
      });
  };
  const exampleType = getTypeForExample();
  const exampleBody = exampleType === exampleTypes.multipart
    ? { body, files: request.files, filesData: state.filesValues }
    : body;
  return (
    <>
      <If condition={pathParams}>
        <Wrapper>
          <Text>Path Parameters</Text>
          <Grid container spacing={2}>
            {renderPathParams(pathParams, state, changePathParams)}
          </Grid>
        </Wrapper>
      </If>
      <If condition={!_.isEmpty(requiredQueryParams)}>
        <Wrapper>
          <Text>Query Parameters</Text>
          <Grid container spacing={2}>
            {renderQueryParams(requiredQueryParams, state, changeQueryParams)}
          </Grid>
        </Wrapper>
      </If>
      <If condition={(request && request.schema) && !(request && request.disableBodyEditor)}>
        <Wrapper>
          <StyledTextField
            multiline
            maxRows={5}
            fullWidth
            error={!!bodyError}
            helperText={bodyError}
            label="Raw Body(json format)"
            value={body}
            onChange={changeBody}
          />
        </Wrapper>
      </If>
      <If condition={files}>
        <Wrapper>
          <Text>{request?.allowMultipleFiles ? 'Upload files' : 'Upload file'}</Text>
          <Grid container spacing={2}>
            {renderFiles(state, changeFilesValues, files, request?.allowMultipleFiles)}
          </Grid>
        </Wrapper>
      </If>
      <CodeExampleWrapper>
        <CodeExample
          contentType={exampleType}
          path={fullPath}
          method={type}
          token={token}
          body={exampleBody}
        >
          <ButtonWrapper sx={{ display: 'flex', py: '12px', px: 1 }}>
            <StyledButton
              disabled={state.processing}
              onClick={handleClick}
              variant="outlined"
            >
              Try it!
            </StyledButton>
          </ButtonWrapper>
        </CodeExample>
      </CodeExampleWrapper>
      <ResponseBox
        res={state.res}
        handleClick={handleClick}
        processing={state.processing}
      />
    </>
  );
}

Interactive.propTypes = {
  operation: PropTypes.shape({
    request: PropTypes.object,
    pathParams: PropTypes.array,
    attributesExample: PropTypes.object,
    type: PropTypes.string,
    path: PropTypes.string,
    query: PropTypes.array,
  }).isRequired,
  id: PropTypes.number.isRequired,
  info: PropTypes.shape({
    token: PropTypes.string,
    domain: PropTypes.string,
    body: PropTypes.string,
    bodyError: PropTypes.string,
  }).isRequired,
  basePath: PropTypes.string.isRequired,
  changeInfo: PropTypes.func.isRequired,
};

export default React.memo(Interactive);
