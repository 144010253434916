import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Typography,
  Toolbar,
  Paper,
  Badge,
  Box,
  Tooltip,
} from '@mui/material';
import { If, Then, Else } from 'react-if';
import { styled } from '@mui/material/styles';
import SectionTitle from './SectionTitle';
import InnerSchemaDialog from './AdditionalTable.js/InnerShema';
import ExampleDialog from './Response';

const Caption = styled(Typography)({
  display: 'block',
});

const StyledTable = styled(Table)({
  minWidth: 600,
});

const TableHeader = styled(Toolbar)(
  ({ theme }) => ({
    paddingLeft: 0,
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const TableCellContent = styled(TableCell)(
  ({ theme }) => ({
    paddingLeft: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const StyledBadge = styled(Badge)(
  ({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -14,
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  }),
);

const InnerSchemaWrapper = styled(Box)(
  ({ theme }) => ({
    marginLeft: theme.spacing(-1),
  }),
);

const ExampleWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const renderQuery = (query) => query.map(({
  name, type, description, example, constraints, required, schema, exampleSchema, isBeta = false,
}) => (
  <TableRow key={name}>
    <TableCellContent>
      <If condition={isBeta}>
        <Then>
          <Tooltip title="This endpoint is currently under active development. Its functionality and structure can be changed.">
            <StyledBadge badgeContent="BETA" color="secondary">
              {name}
            </StyledBadge>
          </Tooltip>
        </Then>
        <Else>
          {name}
        </Else>
      </If>
      <Caption
        variant="caption"
        color="error"
      >
        {required ? 'required' : ''}
      </Caption>
    </TableCellContent>
    <TableCellContent>
      {type}
      <If condition={schema}>
        <Then>
          <InnerSchemaWrapper>
            <InnerSchemaDialog data={schema} name={name} />
          </InnerSchemaWrapper>
        </Then>
      </If>
    </TableCellContent>
    <TableCellContent>{constraints}</TableCellContent>
    <TableCellContent>
      {description}
      <If condition={example}>
        <Then>
          <Typography variant="body2" color="text.secondary">
            {`Example: ${example}`}
          </Typography>
        </Then>
      </If>
      <If condition={exampleSchema}>
        <Then>
          <ExampleWrapper>
            <Typography variant="body2" color="text.secondary">
              Schema example:
            </Typography>
            <ExampleDialog
              header="Open"
              res={{ data: exampleSchema }}
              button
              dialogTitleText="Schema example"
            />
          </ExampleWrapper>
        </Then>
      </If>
    </TableCellContent>
  </TableRow>
));

function Query({ query }) {
  return (
    <Paper elevation={0}>
      <TableHeader>
        <SectionTitle title="Query" hash="#query" />
      </TableHeader>
      <TableContainer>
        <StyledTable size="small">
          <TableHead>
            <TableRow>
              <TableCellContent>Name</TableCellContent>
              <TableCellContent>Type</TableCellContent>
              <TableCellContent>Constraints</TableCellContent>
              <TableCellContent>Description</TableCellContent>
            </TableRow>
          </TableHead>
          <TableBody>{renderQuery(query)}</TableBody>
        </StyledTable>
      </TableContainer>

    </Paper>
  );
}

Query.propTypes = {
  query: PropTypes.array,
};

Query.defaultProps = {
  query: [],
};

export default Query;
