import moment from 'moment';

const validateDateFormat = (date, format) => {
  const isValid = moment(date, format, true).isValid();
  return isValid ? null : `Wrong date format, expected ${format}`;
};

const validators = {
  dateFormat: validateDateFormat,
};

export default validators;
